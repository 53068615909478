.Container {
    position: fixed;
    top: 0;

    width: 100%;
    background: var(--background);

    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    gap: 32px;
}

.LogoContainer {
    position: relative;
    display: flex;

    flex-flow: column;
    align-items: center;
    row-gap: 4px;
    text-decoration: none;
    color: var(--text-color);
    width: 200px;
}

.LogoText {
    text-transform: uppercase;
    font-size: 24px;
    font-family: montserrat, sans-serif;
}

.LogoDivider {
    width: 32px;
    height: 2px;
    background: var(--accent-color);
}

.MenuItem {
    display: inline-block;
    position: relative;

    font-family: montserrat, sans-serif;
    font-size: 18px;
    text-decoration: none;
    color: var(--text-color);
}

.MenuItem::after, .LogoContainer::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -4px;
    left: 0;
    background-color: var(--accent-color);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.MenuItem:hover::after, .LogoContainer:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

@media screen and (max-width: 800px) {
    .Container {
        padding-top: 6px;
        gap: 4px;
    }

    .LogoContainer {
        flex-flow: row;
        margin: 0;
        width: 150px;
    }

    .LogoDivider {
        width: 4px;
        height: 4px;
        border-radius: 2px;
        margin: 0 4px;
    }

    .MenuItem::after, .LogoContainer::after {
        bottom: -2px;
    }
}