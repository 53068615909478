.Container {
    width: var(--text-width);
    margin: 0 auto 32px auto;
}

p {
    margin: 48px 0;
}

.Button {
    display: inline-block;
    background: var(--accent-color);
    color: var(--text-color);
    font-family: barlowcondensed, sans-serif;
    font-size: 24px;
    letter-spacing: .1rem;
    border-radius: 12px;
    padding: 4px 24px;
    margin: 0 auto;
}

a::after {
    display: none
}
