.Container {
    width: 90%;
    margin: 0 auto 32px auto;

    column-count: 4;
    column-width: 300px;
    column-gap: var(--portfolio-image-gap);
}

.Element {
    display: inline-block;
    width: 100%;
}

.Center {
    display: block;
    text-align: center;
    margin: 48px auto;
}

.Loader {
    display: inline-block;
    margin-top: 48px;
    width: 48px;
    height: 48px;
}
.Loader div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 48px;
    height: 48px;
    border: 4px solid var(--accent-color);
    border-radius: 50%;
    animation: Loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--accent-color) transparent transparent transparent;
}
.Loader div:nth-child(1) {
    animation-delay: -0.45s;
}
.Loader div:nth-child(2) {
    animation-delay: -0.3s;
}
.Loader div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes Loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}