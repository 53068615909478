.Container {
    width: var(--text-width);
    margin: 64px auto 32px auto;
}

@media screen and (max-width: 800px) {
    .Container {
        width: 95%;
    }
}

.Signature {
    font-size: 24px;
    font-family: barlowcondensed-light, sans-serif;
    letter-spacing: .1rem;
    font-style: italic;
    color: var(--accent-color)
}

.Picture {
    float: left;
    width: 50%;
    margin-top: 12px;
    object-fit: cover;
    margin-right: 24px;
}

.TextStart {
    margin-top: 24px;
    margin-left: 24px;
}
