.Container {
    width: var(--text-width);
    margin: 0 auto 32px auto;
}

.UploadContainer {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.FileUpload {
    display: none;
}

.Button {
    background: var(--accent-color);
    color: var(--text-color);
    font-family: barlowcondensed, sans-serif;
    font-size: 24px;
    letter-spacing: .1rem;
    outline: 0;
    border-radius: 12px;
    padding: 4px 24px;
    cursor: default;
    width: 50%;
}

.Input {
    font-family: barlowcondensed, sans-serif;
    font-size: 24px;
    letter-spacing: .1rem;
    outline: 0;
    border-radius: 12px;
    padding: 4px 24px;
    width: 50%;
    margin-top: 48px;
    margin-bottom: 24px;
}

